'use client'

import { type ReactNode, createContext, useRef, useContext } from 'react'
import { useStore } from 'zustand'

import {
  type TranscriptionStore,
  createTranscriptionStore,
  initTranscriptionStore,
} from '@/stores/transcription-store'

export type TranscriptionStoreApi = ReturnType<typeof createTranscriptionStore>

export const TranscriptionStoreContext = createContext<TranscriptionStoreApi | undefined>(
  undefined,
)

export interface TranscriptionStoreProviderProps {
  children: ReactNode
}

export const TranscriptionStoreProvider = ({
  children,
}: TranscriptionStoreProviderProps) => {
  const storeRef = useRef<TranscriptionStoreApi>()
  if (!storeRef.current) {
    storeRef.current = createTranscriptionStore(initTranscriptionStore())
  }

  return (
    <TranscriptionStoreContext.Provider value={storeRef.current}>
      {children}
    </TranscriptionStoreContext.Provider>
  )
}

export const useTranscriptionStore = <T,>(
  selector: (store: TranscriptionStore) => T,
): T => {
  const transcriptionStoreContext = useContext(TranscriptionStoreContext)

  if (!transcriptionStoreContext) {
    throw new Error(`useTranscriptionStore must be used within TranscriptionStoreProvider`)
  }

  return useStore(transcriptionStoreContext, selector)
}
