import { createStore } from 'zustand/vanilla';
import { getUserSettings, updateUserSettings } from '@/actions/settings';

export interface UserSettings {
  userId: string;
  aiVoice: string;
  id: string;
  darkMode: 'light' | 'dark' | 'system';
  transcriptionMethod: 'openai' | 'local';
  selectedModel: string;
  temperature: number;
  maxTokens: number;
  autoAnswerEnabled: boolean;
  autoAnswerThreshold: number;
}

export interface SettingsState {
  settings: UserSettings | null;
}

export interface SettingsActions {
  fetchSettings: () => Promise<void>;
  updateSettings: (settings: Partial<UserSettings>) => Promise<void>;
}

export type SettingsStore = SettingsState & SettingsActions;

const initialState: SettingsState = {
  settings: null,
};

export const createSettingsStore = (initState: SettingsState = initialState) => 
  createStore<SettingsStore>()((set) => ({
    ...initState,

    fetchSettings: async () => {
      try {
        const settings = await getUserSettings();
        if (settings) {
          set({ settings: {
            ...settings,
            darkMode: settings.darkMode as 'light' | 'dark' | 'system',
            transcriptionMethod: settings.transcriptionMethod as 'openai' | 'local'
          }});
        } else {
          set({ settings: null });
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
        throw error;
      }
    },

    updateSettings: async (newSettings: Partial<UserSettings>) => {
      try {
        const updatedSettings = await updateUserSettings(newSettings);
        set((state) => ({
          settings: { ...state.settings, ...updatedSettings } as UserSettings,
        }));
      } catch (error) {
        console.error('Failed to update settings:', error);
        throw error;
      }
    },
  }));