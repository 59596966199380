import { createStore } from 'zustand/vanilla';
import { getJobApplications, addJobApplication, updateJobApplication, deleteJobApplication } from '@/actions/jobs';
import { getCVs, addCV, updateCV, deleteCV } from '@/actions/cvs';
import { JobApplication, CV } from '@prisma/client';

export interface JobState {
  jobApplications: JobApplication[],
  cvs: CV[];
  isLoadingJobs: boolean;
  isLoadingCVs: boolean;
}

export interface JobActions {
  // Job application actions
  addJobApplication: (jobApplication: Omit<JobApplication, 'id'>) => Promise<void>;
  updateJobApplication: (id: string, updatedData: Partial<JobApplication>) => Promise<void>;
  deleteJobApplication: (id: string) => Promise<void>;
  // CV actions
  addCV: (name: string, content: string) => Promise<CV | undefined>;
  updateCV: (id: string, name: string, content: string) => Promise<void>;
  deleteCV: (id: string) => Promise<void>;
  // Fetch actions
  fetchJobApplications: () => Promise<void>;
  fetchCVs: () => Promise<void>;
}

export type JobStore = JobState & JobActions;

const initialState: JobState = {
  jobApplications: [],
  cvs: [],
  isLoadingJobs: false,
  isLoadingCVs: false,
};

export const createJobStore = (initState: JobState = initialState) => 
  createStore<JobStore>()((set) => ({
    ...initState,

    addJobApplication: async (jobApplication: Omit<JobApplication, 'id'>) => {
      try {
        const newJobApplication = await addJobApplication(jobApplication);
        set((state) => {
          const updatedJobApplications = [
            ...state.jobApplications,
            {
              ...newJobApplication,
              createdAt: new Date(newJobApplication.createdAt),
              updatedAt: new Date(newJobApplication.updatedAt),
            },
          ];
          return { jobApplications: updatedJobApplications };
        });
      } catch (error) {
        console.error('Failed to add job:', error);
        throw error;
      }
    },

    updateJobApplication: async (id: string, updatedData: Partial<JobApplication>) => {
      try {
        const updatedJobApplication = await updateJobApplication(id, updatedData);
        set((state) => ({
          ...state,
          jobApplications: state.jobApplications.map((jobApplication) =>
            jobApplication.id === id ? {
              ...updatedJobApplication,
              createdAt: new Date(updatedJobApplication.createdAt),
              updatedAt: new Date( updatedJobApplication.updatedAt)
            } : jobApplication
          ),
        }));
      } catch (error) {
        console.error('Failed to update job:', error);
        throw error;
      }
    },

    deleteJobApplication: async (id: string) => {
      try {
        await deleteJobApplication(id);
        set((state) => ({
          jobApplications: state.jobApplications.filter((jobApplication) => jobApplication.id !== id),
        }));
      } catch (error) {
        console.error('Failed to delete job application:', error);
        throw error;
      }
    },

    fetchCVs: async () => {
      set({ isLoadingCVs: true });
      try {
        const cvs = await getCVs();
        console.log("🚀 ~ fetchCVs: ~ cvs:", cvs)
        set({ 
          cvs: cvs.map(cv => ({
            ...cv,
            createdAt: new Date(cv.createdAt),
            updatedAt: new Date()
          })),
          isLoadingCVs: false
        });
      } catch (error) {
        console.error('Failed to fetch CVs:', error);
        set({ isLoadingCVs: false });
        throw error;
      }
    },

    addCV: async (name: string, content: string) => {
      try {
        const newCV = await addCV(name, content);
        const formattedCV = {
          ...newCV,
          createdAt: new Date(newCV.createdAt),
          updatedAt: new Date(newCV.updatedAt)
        };
        set((state) => ({
          ...state,
          cvs: [...state.cvs, formattedCV],
        }));
        return formattedCV; // Return the new CV
      } catch (error) {
        console.error('Failed to add CV:', error);
        throw error;
      }
    },

    updateCV: async (id: string, name: string, content: string) => {
      try {
        const updatedCV = await updateCV(id, name, content);
        set((state) => ({
          cvs: state.cvs.map((cv) =>
            cv.id === id ? {
              ...updatedCV,
              createdAt: new Date(updatedCV.createdAt), 
              updatedAt: new Date(updatedCV.updatedAt)
            } : cv
          ),
        }));
      } catch (error) {
        console.error('Failed to update CV:', error);
        throw error;
      }
    },

    deleteCV: async (id: string) => {
      try {
        await deleteCV(id);
        set((state) => ({
          cvs: state.cvs.filter((cv) => cv.id !== id),
        }));
      } catch (error) {
        console.error('Failed to delete CV:', error);
        throw error;
      }
    },

    fetchJobApplications: async () => {
      set({ isLoadingJobs: true });
      try {
        const jobApplications = await getJobApplications();
        
        set({ 
          jobApplications: jobApplications.map(jobApplication => ({
            ...jobApplication,
            createdAt: new Date(jobApplication.createdAt), 
            updatedAt: new Date(jobApplication.updatedAt)
          })),
          isLoadingJobs: false
        });
      } catch (error) {
        console.error('Failed to fetch jobs:', error);
        set({ isLoadingJobs: false });
        throw error;
      }
    },
  }));