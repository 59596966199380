'use client';

import { useState, useEffect } from 'react';

interface User {
  id: string;
  email: string;
  name: string | null;
}

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const res = await fetch('/.auth/me');
        const data = await res.json();

        if (data && data.length > 0) {
          const userData = data[0];
          const userId = userData.user_id;
          const name = userData.user_claims.find((claim: any) => claim.typ === "name")?.val;
          const userInfo = {
            email: userId || '',
            name: name || null,
          };

          // Call API to upsert user and get ID
          const response = await fetch('/api/user', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userInfo),
          });

          if (response.ok) {
            const userData = await response.json();
            setUser(userData);
          } else {
            throw new Error('Failed to upsert user');
          }
        } else {
          throw new Error('Not authenticated');
        }
      } catch (error) {
        console.error('Authentication error:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    fetchAuth();
  }, []);

  return { user, loading };
}