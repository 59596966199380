import { createStore } from 'zustand/vanilla'
import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk';
import Cookie from 'universal-cookie';

export type TranscriptionState = {
  interimTranscription: string
  transcription: string
  isRecording: boolean
  recognizer: speechsdk.SpeechRecognizer | null
  transcriber: speechsdk.ConversationTranscriber | null
  conversationTranscriber: speechsdk.ConversationTranscriber | null
}

export type TranscriptionActions = {
  clearTranscription: () => void
  startRecording: () => Promise<void>
  stopRecording: () => Promise<void>
  appendTranscription: (text: string) => void
  setupSpeechRecognition: () => Promise<void>
  refreshToken: () => Promise<void>
  setupConversationTranscriber: () => Promise<void>
  startConversationTranscriber: () => Promise<void>
  stopConversationTranscriber: () => Promise<void>
  updateInterimTranscription: (text: string) => void
}

export type TranscriptionStore = TranscriptionState & TranscriptionActions

const getTokenOrRefresh = async (forceRefresh = true): Promise<{ authToken: string | null, region?: string, error?: string }> => {
  const cookie = new Cookie();
  const speechToken = cookie.get('speech-token');

  if (speechToken === undefined || forceRefresh) {
    try {
      console.log('[Token] Fetching new token...');
      const res = await fetch('/api/get-speech-token');
      const data = await res.json();
      const token = data.token;
      const region = data.region;
      console.log(`[Token] New token fetched: ${token ? 'Success' : 'Failure'}`);
      cookie.set('speech-token', `${region}:${token}`, { maxAge: 540, path: '/' }); // Set to 9 minutes
      return { authToken: token, region: region };
    } catch (err: any) {
      console.error('[Token] Error fetching token:', err);
      return { authToken: null, error: err.message };
    }
  } else {
    console.log('[Token] Using existing token from cookie');
    const idx = speechToken.indexOf(':');
    return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
  }
}

export const initTranscriptionStore = (): TranscriptionState => ({
  interimTranscription: "",
  transcription: "",
  isRecording: false,
  recognizer: null,
  transcriber: null,
  conversationTranscriber: null,
})
export const createTranscriptionStore = (initState: TranscriptionState = initTranscriptionStore()) => {
  return createStore<TranscriptionStore>((set, get) => ({
    ...initState,
    clearTranscription: () => {
      console.log('[Transcription] Clearing transcription');
      set({ transcription: "" });
    },
    startRecording: async () => {
      console.log('[Recording] Attempting to start recording');
      const { recognizer, conversationTranscriber } = get();
      if (recognizer) {
        try {
          await recognizer.startContinuousRecognitionAsync();
          console.log('[Recording] Continuous recognition started');
          set({ isRecording: true });
        } catch (error) {
          console.error('[Recording] Error starting continuous recognition:', error);
          console.log('[Recording] Attempting to set up speech recognition again');
          await get().setupSpeechRecognition();
        }
      } else if (conversationTranscriber) {
        console.log('[Recording] Using conversation transcriber');
        await get().startConversationTranscriber();
      } else {
        console.log('[Recording] No recognizer or transcriber found. Setting up speech recognition');
        await get().setupSpeechRecognition();
        await get().startRecording();
      }
    },
    stopRecording: async () => {
      console.log('[Recording] Attempting to stop recording');
      const { recognizer, conversationTranscriber } = get();
      if (recognizer) {
        try {
          await recognizer.stopContinuousRecognitionAsync();
          console.log('[Recording] Continuous recognition stopped');
        } catch (error) {
          console.error('[Recording] Error stopping continuous recognition:', error);
        } finally {
          set({ isRecording: false });
        }
      } else if (conversationTranscriber) {
        console.log('[Recording] Stopping conversation transcription');
        await get().stopConversationTranscriber();
      }
    },
    appendTranscription: (text) => {
      console.log('[Transcription] Appending new text:', text);
      set((state) => ({ 
        transcription: state.transcription + (state.transcription ? "\n" : "") + text 
      }));
    },
    setupSpeechRecognition: async () => {
      console.log('[Speech Recognition] Setting up speech recognition');
      const tokenObj = await getTokenOrRefresh(true);
      if (!tokenObj.authToken || !tokenObj.region) {
        console.error('[Speech Recognition] Failed to fetch token or region');
        throw new Error('Unable to fetch token or region.');
      }

      const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
      speechConfig.speechRecognitionLanguage = 'en-US';
      speechConfig.enableAudioLogging()
      speechConfig.setProfanity(speechsdk.ProfanityOption.Raw);
      // Adjust properties to disable noise suppression and echo cancellation
      speechConfig.setProperty("SpeechServiceConnection_SuppressNoise", "false");
      speechConfig.setProperty("SpeechServiceConnection_SuppressEcho", "false");
      
      // speechConfig.setProperty("ConversationTranscriptionInRoomAndOnline", "true");
      // speechConfig.setProperty("DifferentiateGuestSpeakers", "true");

      const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();      
      const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

      recognizer.recognizing = (s, e) => {
        console.log(`[Speech Recognition] RECOGNIZING: Text=${e.result.text}`);
        get().updateInterimTranscription(e.result.text);
      };

      recognizer.recognized = (s, e) => {
        if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
          console.log(`[Speech Recognition] RECOGNIZED: Text=${e.result.text}`);
          get().updateInterimTranscription(""); // Clear interim transcription
          get().appendTranscription(e.result.text);
        }
      };

      recognizer.canceled = async (s, e) => {
        console.log(`[Speech Recognition] CANCELED: Reason=${e.reason}`);
        if (e.reason == speechsdk.CancellationReason.Error) {
          console.log(`[Speech Recognition] CANCELED: ErrorCode=${e.errorCode}`);
          console.log(`[Speech Recognition] CANCELED: ErrorDetails=${e.errorDetails}`);
          
          console.log('[Speech Recognition] Attempting to refresh token and reconnect');
          await get().refreshToken();
          await get().setupSpeechRecognition();
        }
      };

      console.log('[Speech Recognition] Speech recognizer set up successfully');
      set({ recognizer });
    },
    refreshToken: async () => {
      console.log('[Token] Refreshing token...');
      const tokenObj = await getTokenOrRefresh(true);
      if (tokenObj.authToken && tokenObj.region) {
        const { recognizer, conversationTranscriber } = get();
        if (recognizer) {
          console.log('[Token] Updating recognizer with new token');
          const newSpeechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
          const newRecognizer = new speechsdk.SpeechRecognizer(newSpeechConfig, speechsdk.AudioConfig.fromDefaultMicrophoneInput());
          set({ recognizer: newRecognizer });
        }
        if (conversationTranscriber) {
          console.log('[Token] Updating conversation transcriber with new token');
          const newSpeechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
          const newConversationTranscriber = new speechsdk.ConversationTranscriber(newSpeechConfig);
          set({ conversationTranscriber: newConversationTranscriber });
        }
        console.log('[Token] Token refresh completed successfully');
      } else {
        console.error('[Token] Failed to refresh token');
      }
    },
    setupConversationTranscriber: async () => {
      console.log('[Conversation Transcriber] Setting up conversation transcriber');
      const tokenObj = await getTokenOrRefresh(true);
      if (!tokenObj.authToken || !tokenObj.region) {
        console.error('[Conversation Transcriber] Failed to fetch token or region');
        throw new Error('Unable to fetch token or region.');
      }

      const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
      speechConfig.speechRecognitionLanguage = 'en-US';
      speechConfig.enableAudioLogging()
      speechConfig.setProfanity(speechsdk.ProfanityOption.Raw);
      // Adjust properties to disable noise suppression and echo cancellation
      speechConfig.setProperty("SpeechServiceConnection_SuppressNoise", "false");
      speechConfig.setProperty("SpeechServiceConnection_SuppressEcho", "false");
      
      // speechConfig.setProperty("ConversationTranscriptionInRoomAndOnline", "true");
      // speechConfig.setProperty("DifferentiateGuestSpeakers", "true");

      const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
      const conversationTranscriber = new speechsdk.ConversationTranscriber(speechConfig, audioConfig);

      conversationTranscriber.transcribing = (s, e) => {
        // TODO: Display this on UI
        get().updateInterimTranscription(e.result.text);
        console.log(`[Conversation Transcriber] TRANSCRIBING: Text=${e.result.text}`);
      };

      conversationTranscriber.transcribed = (s, e) => {
        if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
          console.log(`[Conversation Transcriber] TRANSCRIBED: Text=${e.result.text}`);
          // TODO: Use it for AI processing
          get().appendTranscription(`${e.result.speakerId}: ${e.result.text}`);
          get().updateInterimTranscription("");
        }
      };

      conversationTranscriber.canceled = async (s, e) => {
        console.log(`[Conversation Transcriber] CANCELED: Reason=${e.reason}`);
        if (e.reason == speechsdk.CancellationReason.Error) {
          console.log(`[Conversation Transcriber] CANCELED: ErrorCode=${e.errorCode}`);
          console.log(`[Conversation Transcriber] CANCELED: ErrorDetails=${e.errorDetails}`);
          
          console.log('[Conversation Transcriber] Attempting to refresh token and reconnect');
          await get().refreshToken();
          await get().setupConversationTranscriber();
        }
      };

      console.log('[Conversation Transcriber] Conversation transcriber set up successfully');
      set({ conversationTranscriber });
    },

    startConversationTranscriber: async () => {
      console.log('[Conversation Transcriber] Starting conversation transcription');
      const { conversationTranscriber } = get();
      if (conversationTranscriber) {
        try {
          await new Promise<void>((resolve, reject) => {
            get().conversationTranscriber?.startTranscribingAsync(
              () => {
                console.log('[Conversation Transcriber] Conversation transcription started');
                set({ isRecording: true });
                resolve();
              },
              (err) => {
                console.error('[Conversation Transcriber] Error starting conversation transcription:', err);
                reject(err);
              }
            );
          });
        } catch (error) {
          console.error('[Conversation Transcriber] Failed to start recording:', error);
          console.log('[Conversation Transcriber] Attempting to set up conversation transcriber again');
          await get().setupConversationTranscriber();
        }
      } else {
        console.log('[Conversation Transcriber] No conversation transcriber found. Setting up conversation transcriber');
        // await get().setupConversationTranscriber();
        // await get().startConversationTranscriber();
      }
    },
    stopConversationTranscriber: async () => {
      console.log('[Conversation Transcriber] Stopping conversation transcription');
      const { conversationTranscriber } = get();
      if (conversationTranscriber) {
        try {
          await new Promise<void>((resolve, reject) => {
            conversationTranscriber.stopTranscribingAsync(
              () => {
                console.log('[Conversation Transcriber] Conversation transcription stopped');
                set({ isRecording: false });
                resolve();
              },
              (err) => {
                console.error('[Conversation Transcriber] Error stopping conversation transcription:', err);
                reject(err);
              }
            );
          });
        } catch (error) {
          console.error('[Conversation Transcriber] Failed to stop recording:', error);
        }
      }
    },
    updateInterimTranscription: (text) => {
      console.log('[Transcription] Updating interim transcription:', text);
      set({ interimTranscription: text });
    },
  }))
}